.react-datepicker-wrapper {
  margin: 0 0;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  background: none;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom: none;
}

.react-datepicker__day-names {
  margin-top: 5px;
}

.react-datepicker__week {
  margin-top: 0px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0;
  background-color: #e4e4e4;
  color: #565a5c;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #024fa5 !important;
  color: #fff !important;
}

.react-datepicker__day--in-selecting-range-end {
  background-color: #024fa5 !important;
  color: #fff !important;
}

.react-datepicker__day--disabled:hover {
  background: none;
}

.react-datepicker__day--in-range {
  background: #f0f0f0;
  color: #565a5c;
}

.react-datepicker__day--range-start {
  background-color: #024fa5 !important;
  color: #fff !important;
}

.react-datepicker__day--range-end {
  background-color: #024fa5 !important;
  color: #fff !important;
}
.react-datepicker__navigation:hover {
  border: 1px solid #757575;
  border-radius: 3px;
}
.react-datepicker__navigation--next {
  background: url('/img/datepicker-arrow-right.svg');
  border: none;
  width: 36px;
  height: 28px;
  background-size: 19px 19px;
  background-position: center center;
  background-repeat: no-repeat;
  top: 28px;
  right: 25px;
}

.react-datepicker__navigation--previous {
  background: url('/img/datepicker-arrow-left.svg');
  border: none;
  width: 36px;
  height: 28px;
  background-size: 19px 19px;
  background-position: center center;
  background-repeat: no-repeat;
  top: 28px;
  left: 25px;
}

.react-datepicker-popper {
  width: 318px;
}

.react-datepicker__header {
  background: none;
  border-bottom: none;
  padding-top: 30px;
}

.react-datepicker__month {
  margin: 0px 8px 0px 8px;
}

.react-datepicker__current-month {
  font-size: 18px;
  font-weight: normal;
}

.react-datepicker__day-name {
  width: 32px;
  margin-top: 10px;
  line-height: 1;
}

.react-datepicker {
  font-family: 'TradeGothicRegular', Arial, sans-serif;
  width: 100%;
  border-radius: 3px;
  border: 0.5px solid #e4e7e7;
}

.react-datepicker__month-container {
  width: 100%;
  padding: 0px 16px 16px 16px;
}

.react-datepicker__month {
  width: 266px;
}

.react-datepicker__day--001 {
  border-left: 0.5px solid #e4e7e7;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  color: transparent;
  pointer-events: none;
  border-top: none;
  border-right: none;
}

.react-datepicker__day {
  width: 38px;
  height: 38px;
  margin: 0 0;
  font-size: 16px;
  border-bottom: 0.5px solid #e4e7e7;
  border-right: 0.5px solid #e4e7e7;
  padding-top: 7px;
}
.react-datepicker__day:first-child {
  border-left: 0.5px solid #e4e7e7;
}

.react-datepicker__week:last-child .react-datepicker__day {
  border-bottom: 0.5px solid #e4e7e7;
}

.react-datepicker__week:first-child .react-datepicker__day--outside-month {
  border-top: none;
}

.react-datepicker__week:first-child .react-datepicker__day {
  border-top: 0.5px solid #e4e7e7;
}

.react-datepicker__week:first-child
  .react-datepicker__day.react-datepicker__day--outside-month {
  border-top: none;
}
.react-datepicker__week:last-child
  .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--001 {
  border-left: none;
}

.react-datepicker__week:last-child .react-datepicker__day--outside-month {
  border-bottom: none;
}

.react-datepicker__week:first-child .react-datepicker__day--outside-month {
  border-left: none;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range :hover {
  border-radius: 0;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0;
  background-color: #f0f0f0;
}

.react-datepicker__day.react-datepicker__day--disabled:hover {
  background-color: #fff;
}

.react-datepicker__time-container {
  width: 100%;
}

.react-datepicker__time-container .react-datepicker__header {
  padding-top: 10px;
}
